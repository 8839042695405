import { all, takeEvery, takeLatest } from "redux-saga/effects"
import {
  GET_BACKER,
  GET_BACKER_SUCCESS,
  GET_GOOGLE_CONVERSIONS,
  GET_GOOGLE_CONVERSIONS_SUCCESS,
  GET_META_CONVERSIONS,
  GET_META_CONVERSIONS_SUCCESS,
  GET_PROJECT_VIA_CODE,
  GET_PROJECT_VIA_CODE_FAILED,
  GET_PROJECT_VIA_CODE_SUCCESS,
} from "../actions/consts"
import handleGetBacker from "./get-backer"
import handleGetGoogleConversions from "./get-google-conversions"
import handleMetaConversion from "./meta-conversion"
import handleGetProjectViaCode from "./get-project-via-code"
import handleConversions from "./conversions"
import handleFallback from "./fallback-tracking"
import handleTikTokTracking from "./tiktok-tracking"
import handleRedirect404 from "./redirect-404"
import handleMetaTracking from "./meta-tracking"
import handleGoogleTracking from "./google-tracking"

export default function* sagas() {
  yield all([
    takeEvery(GET_BACKER, handleGetBacker),
    takeEvery(GET_GOOGLE_CONVERSIONS, handleGetGoogleConversions),
    takeLatest(GET_META_CONVERSIONS, handleMetaConversion),
    takeLatest(GET_PROJECT_VIA_CODE, handleGetProjectViaCode),
    takeLatest(GET_BACKER_SUCCESS, handleConversions),
    takeLatest(GET_PROJECT_VIA_CODE_SUCCESS, handleFallback),
    takeLatest(
      [GET_BACKER_SUCCESS, GET_PROJECT_VIA_CODE_SUCCESS],
      handleTikTokTracking
    ),
    takeLatest([GET_PROJECT_VIA_CODE_FAILED], handleRedirect404),
    takeLatest(GET_META_CONVERSIONS_SUCCESS, handleMetaTracking),
    takeLatest(GET_GOOGLE_CONVERSIONS_SUCCESS, handleGoogleTracking),
  ])
}
