import { storageWrapper } from "./localstorage"

const FBPIXEL_TRACK_KEY = "backervoice-facebook-pixel-tracker"

const tracker = () =>
  JSON.parse(storageWrapper.getItem(FBPIXEL_TRACK_KEY)) || {}

const incrementTracker = code => {
  const fbPixelTracker = tracker()

  fbPixelTracker[code] = fbPixelTracker[code] + 1 || 1

  storageWrapper.setItem(FBPIXEL_TRACK_KEY, JSON.stringify(fbPixelTracker))
}

const wasTracked = code => {
  const fbPixelTracker = tracker()

  return fbPixelTracker[code] > 0
}

export const setupFacebook = () => {
  return !(function(f, b, e, v, n, t, s) {
    if (f.fbq) {
      return
    }
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) {
      f._fbq = n
    }
    n.push = n
    n.loaded = !0
    n.version = "2.0"
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  )
}

const trackedPixelIds = new Map()

export const buildFacebook = (conversions, ref) => {
  if (!window.fbq) {
    console.log("Facebook Pixel script not setup, setting it up...")
    setupFacebook()
  }

  if (wasTracked(ref)) {
    return
  }

  for (let i = 0; i < conversions.length; i++) {
    const pixel = conversions[i]
    const { data = [] } = pixel.request

    const externalIDs = data
      .map(({ user_data: { external_id } = {} }) => external_id)
      .filter(Boolean)

    if (!trackedPixelIds.has(pixel.pixel_id)) {
      init(pixel.pixel_id, externalIDs[0])

      trackedPixelIds.set(pixel.pixel_id, true)
    }

    data.forEach(({ event_name, custom_data, event_id }) => {
      trackSingle(pixel.pixel_id, event_name, custom_data, event_id)
    })
  }

  incrementTracker(ref)
}

export const buildFallback = ({ pixels = [], value, currency, ref }) => {
  if (wasTracked(ref)) {
    return
  }

  pixels.forEach(({ code }) => {
    init(code)
    trackPurchase(code, {
      value,
      currency,
    })
  })

  trackPageView()

  incrementTracker(ref)
}

const fbq = (...args) => {
  window.fbq(...args)
}

export const init = (pixelId, externalID) => {
  if (!!externalID) {
    fbq("init", pixelId, { external_id: externalID })
  }

  fbq("init", pixelId)
}

const trackSingle = (pixelId, eventName, customData, eventID) => {
  fbq("trackSingle", pixelId, eventName, customData, { eventID })
}
const track = eventName => {
  fbq("track", eventName)
}

export const trackPurchase = (pixelId, customData, eventID) => {
  trackSingle(pixelId, "Purchase", customData, eventID)
}

export const trackPageView = () => {
  track("PageView")
}
