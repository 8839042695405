import { call } from "redux-saga/effects"
import { withinLast7Days } from "../utils/dates"
import { buildFallback } from "../utils/facebook-pixel"
import { trackSingleConversion } from "../utils/googletag-manager"
import { isLive } from "../utils/project"

export default function* handleFallback({ payload }) {
  const {
    id,
    project_status,
    project_end_date,
    currency,
    avg_pledged,
    survey_google_id,
    facebook_pixels = [],
  } = payload.data

  if (isLive(project_status) || withinLast7Days(project_end_date)) {
    yield call(buildFallback, {
      pixels: facebook_pixels,
      value: Math.round(avg_pledged),
      currency: currency,
      ref: `${id}-survey`,
    })

    yield call(trackSingleConversion, {
      send_to: survey_google_id,
      value: Math.round(avg_pledged),
      currency: currency,
      ref: `${id}-survey`,
    })
  }
}
