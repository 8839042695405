export const GET_BACKER = "GET_BACKER"
export const GET_BACKER_SUCCESS = "GET_BACKER_SUCCESS"
export const GET_BACKER_FAILED = "GET_BACKER_FAILED"

export const GET_GOOGLE_CONVERSIONS = "GET_GOOGLE_CONVERSIONS"
export const GET_GOOGLE_CONVERSIONS_SUCCESS = "GET_GOOGLE_CONVERSIONS_SUCCESS"
export const GET_GOOGLE_CONVERSIONS_FAILED = "GET_GOOGLE_CONVERSIONS_FAILED"

export const GET_META_CONVERSIONS = "GET_META_CONVERSIONS"
export const GET_META_CONVERSIONS_SUCCESS = "GET_META_CONVERSIONS_SUCCESS"
export const GET_META_CONVERSIONS_FAILED = "GET_META_CONVERSIONS_FAILED"

export const GET_PROJECT_VIA_CODE = "GET_PROJECT_VIA_CODE"
export const GET_PROJECT_VIA_CODE_SUCCESS = "GET_PROJECT_VIA_CODE_SUCCESS"
export const GET_PROJECT_VIA_CODE_FAILED = "GET_PROJECT_VIA_CODE_FAILED"