import { call, select } from "redux-saga/effects"
import {
  getProjectId,
  getProjectStatus,
  getProjectEndDate,
  getGoogleSurveyId,
  getPledgeAmount,
  getProjectCurrency,
} from "../reducers/projects"
import { withinLast7Days } from "../utils/dates"
import { isLive } from "../utils/project"
import { trackConversions } from "../utils/googletag-manager"

export default function* handleGoogleTracking({ payload }) {
  const { id, data } = payload
  const conversionsData = data.data

  const projectId = yield select(getProjectId)
  const projectStatus = yield select(getProjectStatus)
  const projectEndDate = yield select(getProjectEndDate)
  const sendTo = yield select(getGoogleSurveyId)
  const amount = yield select(getPledgeAmount)
  const currency = yield select(getProjectCurrency)

  if (
    conversionsData?.length > 0 &&
    (isLive(projectStatus) || withinLast7Days(projectEndDate))
  ) {
    yield call(
      trackConversions,
      {
        send_to: sendTo,
        value: amount,
        currency,
        ref: `${projectId}-survey-${id}`,
      },
      conversionsData
    )
  }
}
