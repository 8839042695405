import { put } from "redux-saga/effects"
import { getCookieValue } from "../utils/cookies"
import { getConversions, getMetaConversions } from "../actions"
import { withinLast7Days } from "../utils/dates"
import { isLive } from "../utils/project"

export default function* handleConversions({ payload }) {
  const {
    uuid,
    has_pixel_on_ks_dashboard,
    project_status,
    project_end_date,
  } = payload.data

  if (isLive(project_status) || withinLast7Days(project_end_date)) {
    yield put(getConversions(uuid))

    if (!has_pixel_on_ks_dashboard) {
      yield put(
        getMetaConversions(uuid, {
          event_source_url: window.location.href,
          fbp: getCookieValue("_fbp"),
          fbc: getCookieValue("_fbc"),
        })
      )
    }
  }
}
