import { call, put } from "redux-saga/effects"
import { getConversionsFailed, getConversionsSuccess } from "../actions"
import { backervoiceApi } from "../apis"

export default function* handleGetGoogleConversions({ payload }) {
  try {
    const data = yield call(backervoiceApi.getConversions, payload.id)

    yield put(getConversionsSuccess(payload.id, data))
  } catch (e) {
    yield put(getConversionsFailed(e))
  }
}
