import { call, put } from "redux-saga/effects"
import { getProjectViaCodeFailed, getProjectViaCodeSuccess } from "../actions"
import { backervoiceApi } from "../apis"

export default function* handleGetProjectViaCode({ payload }) {
  try {
    const data = yield call(backervoiceApi.getProjectViaCode, payload.code)

    yield put(getProjectViaCodeSuccess(data))
  } catch (e) {
    yield put(getProjectViaCodeFailed(e))
  }
}
