import {
  GET_BACKER_SUCCESS,
  GET_GOOGLE_CONVERSIONS_SUCCESS,
  GET_META_CONVERSIONS_SUCCESS,
  GET_PROJECT_VIA_CODE_SUCCESS,
} from "../actions/consts"

const initialState = {
  backer: null,
  conversions: null,
  metaConversions: null,
  project: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BACKER_SUCCESS:
      return { ...state, backer: payload.data }
    case GET_GOOGLE_CONVERSIONS_SUCCESS:
      return { ...state, conversions: payload.data.data }
    case GET_META_CONVERSIONS_SUCCESS:
      return { ...state, metaConversions: payload.data }
    case GET_PROJECT_VIA_CODE_SUCCESS:
      return { ...state, project: payload.data }

    default:
      return state
  }
}

export const getProjects = state => state.projects
export const getProjectsBacker = state => getProjects(state).backer
export const getProjectsGoogleConversions = state =>
  getProjects(state).conversions
export const getProjectsMetaConversions = state =>
  getProjects(state).metaConversions || []
export const getProject = state => getProjects(state).project

export const isLoadingSurvey = state =>
  !getProjectsBacker(state) && !getProject(state)

export const getProjectId = state =>
  getProjectsBacker(state)?.project_id || getProject(state)?.id

export const getProjectCurrency = state =>
  getProjectsBacker(state)?.currency || getProject(state)?.currency

export const getGoogleSurveyId = state =>
  getProjectsBacker(state)?.survey_google_id ||
  getProject(state)?.survey_google_id

export const getSurveyUrl = state =>
  getProjectsBacker(state)?.survey_url || getProject(state)?.survey_url

export const getPledgeAmount = state =>
  getProjectsBacker(state)?.amount || getProject(state)?.avg_pledged

export const getProjectStatus = state =>
  getProjectsBacker(state)?.project_status || getProject(state)?.project_status

export const getProjectEndDate = state =>
  getProjectsBacker(state)?.project_end_date ||
  getProject(state)?.project_end_date

export const getHasAltTracking = state =>
  getProjectsBacker(state)?.has_pixel_on_ks_dashboard ||
  getProject(state)?.has_pixel_on_ks_dashboard

export const getTikTokPixels = state =>
  getProjectsBacker(state)?.tiktok_pixels || getProject(state)?.tiktok_pixels

export const getFacebookPixels = state => getProject(state)?.facebook_pixels
