import { call } from "redux-saga/effects"
import { withinLast7Days } from "../utils/dates"
import { buildMultipleTiktok } from "../utils/tiktok-pixel"
import { isLive } from "../utils/project"

export default function* handleTikTokTracking({ payload }) {
  const {
    project_status,
    project_end_date,
    currency,
    amount,
    avg_pledged,
    tiktok_pixels,
  } = payload.data

  if (isLive(project_status) || withinLast7Days(project_end_date)) {
    yield call(buildMultipleTiktok, tiktok_pixels, {
      currency: currency,
      value: amount || Math.round(avg_pledged),
    })
  }
}
