import { call, select } from "redux-saga/effects"
import {
  getProjectId,
  getProjectStatus,
  getProjectEndDate,
} from "../reducers/projects"
import { buildFacebook } from "../utils/facebook-pixel"
import { withinLast7Days } from "../utils/dates"
import { isLive } from "../utils/project"

export default function* handleMetaTracking({ payload }) {
  const { id, data } = payload

  const projectId = yield select(getProjectId)
  const projectStatus = yield select(getProjectStatus)
  const projectEndDate = yield select(getProjectEndDate)

  if (
    data?.length > 0 &&
    (isLive(projectStatus) || withinLast7Days(projectEndDate))
  ) {
    yield call(buildFacebook, data, `${projectId}-survey-${id}`)
  }
}
